import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetDailyRoutesService } from '../get-daily-routes.service';
import { getDailyRoutesAction, getDailyRoutesSuccessAction, getDailyRoutesFailureAction } from './get-daily-routes.actions';

@Injectable()
export class GetDailyRoutesEffects {
  getDailyRoutes$ = createEffect(() => this.actions$.pipe(
    ofType(getDailyRoutesAction),
    switchMap(action =>
      this.getDailyRoutesService.get(action.payload).pipe(
        map(data => getDailyRoutesSuccessAction({ data })),
        catchError(error => of(getDailyRoutesFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getDailyRoutesService: GetDailyRoutesService,
  ) {
  }
}
