import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Firestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { DailyRouteWithUserId, getDailyRoutesByDateRange$, GetDailyRoutesPayload } from '../../data-access/daily-route';
import { isArray } from "lodash";

@Injectable({
  providedIn: 'root',
})
export class GetDailyRoutesService {

  constructor(private firestore: Firestore) {
  }

  get(payload: GetDailyRoutesPayload): Observable<DailyRouteWithUserId[]> {
    const userId = payload.userId;
    return isArray(userId) ?
      combineLatest(userId.map((userId: string) =>
        getDailyRoutesByDateRange$(this.firestore, userId, payload.dateRange).pipe(
          map(dailyRoutes => dailyRoutes.map(d => ({ ...d, userId })))
        )
      )).pipe(
        map(dailyRoutes => dailyRoutes.flat()),
      ) : getDailyRoutesByDateRange$(this.firestore, userId, payload.dateRange).pipe(
        map(dailyRoutes => dailyRoutes.map(d => ({ ...d, userId }))),
      );
  }
}
