import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetDailyRoutesEffects } from './store/get-daily-routes.effects';
import { reducer } from './store/get-daily-routes.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetDailyRoutesEffects ]),
    StoreModule.forFeature('getDailyRoutesFb', reducer),
  ]
})
export class GetDailyRoutesModule {}
