import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';

export const getReportFiltersAction = createAction(
  ActionTypes.GET_REPORT_FILTERS,
  props<{ scope: 'dailyRoutes' | 'accounts' | 'activity' }>()
);

export const getReportFiltersSuccessAction = createAction(
  ActionTypes.GET_REPORT_FILTERS_SUCCESS,
  props<{ data: any }>()
);

export const getReportFiltersFailureAction = createAction(
  ActionTypes.GET_REPORT_FILTERS_FAILURE,
  props<{ error: any }>()
);
