import { Action, createReducer, on } from '@ngrx/store';
import {
  clearDailyRoutesAction,
  getDailyRoutesAction,
  getDailyRoutesFailureAction,
  getDailyRoutesSuccessAction,
} from './get-daily-routes.actions';
import { GetDailyRoutesState } from './get-daily-routes.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getDailyRoutesReducer = createReducer<GetDailyRoutesState>(
  initialState,
  on(
    getDailyRoutesAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getDailyRoutesSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getDailyRoutesFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
  on(
    clearDailyRoutesAction,
    () => initialState
  ),
);

export const reducer = (state: GetDailyRoutesState, action: Action) => getDailyRoutesReducer(state, action);
